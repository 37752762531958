/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
/* 読込: コンポーネント */
import SystemListPc from './SystemListPc';
/* 読込: CSS */
import '../../css/System.css';
/* 読込: その他 */

function SystemPc (props) {

    const dayList = ["日", "月", "火", "水", "木", "金", "土"]; // 曜日確認用

    const [systemReportList, setSystemReportList] = useState([]);  // 一覧表示する記事
    const [systemReportIdLast, setSystemReportIdLast] = useState(0);  // 一覧表示する記事の最大ID (初期アクセス時は0)
    const [firstLoadFlg, setFirstLoadFlg] = useState(true);
    var serverConnectFlg = false;   // サーバーとの通信中かどうかを管理 (true:通信中 false:通信中でない)
    var systemReportKeyList = [];   // 記事のキー配列

    /*
    * Expressから記事情報を取得
     *  - reportIdLast(int) : 取得する記事の最大ID (初期アクセス時は0)
     *  - reportCount(int) : 取得する記事数
     *  - keyword(string) : 検索キーワード
     *  - sortType(int) : 並び順タイプ (1:日付順 2:閲覧数順)
     *  - sortTypeDateTime(int) : 並び順(日付順)の昇順・降順 (1:昇順 2:降順)
     *  - sortTypeView(int) : 並び順(閲覧順)の昇順・降順 (1:昇順 2:降順)
    */

    const getList = async() => {
        if (!serverConnectFlg) {
            serverConnectFlg = true;

            const params = { // 渡したいパラメータをJSON形式で書く
                reportIdLast: systemReportIdLast,
                reportCount: process.env.REACT_APP_SYSTEM_REPORT_COUNT,
                keyword: "",
                sortType: 1,
                sortTypeDateTime: 1,
                sortTypeView: 1
            };

            const query_params = new URLSearchParams(params); 
            await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'system/list?' + query_params)
            .then(resp => resp.json())
            .then(res =>{
                // Expressの処理でエラーがあった場合
                if (Array.isArray(res.errors) && res.errors.length > 0){
                    console.log("in error");
                    for (var i = 0; i < res.errors.length; i++){
                        var error = res.errors[i];
                        console.error(error.path + "(val:" + error.value + "):" + error.msg);
                    }
                }else{
                    // データをセット
                    const resList = JSON.parse(res);
                    for (let key in resList){
                        if (systemReportKeyList.indexOf(key) === -1){
                            // 日付の形式を変更
                            var updateDateStr = resList[key]['systemDatSystem']['updatedAt'].substr(0, 10);
                            updateDateStr = updateDateStr.replace(/-/g, '/');
                            const updateDate = new Date(updateDateStr);

                            // 配列にキーがない場合のみ追加
                            var systemReport= {
                                key: key,
                                title:resList[key]['systemDatSystem']['systemReportTitle'],
                                thumbnailPath:resList[key]['thumbnail'],
                                tagList:resList[key]['tagList'],
                                prologue:resList[key]['contentPrologue'],
                                updatedYmd: updateDateStr + ' (' + dayList[updateDate.getDay()] + ')',
                                views: resList[key]['view']
                            };
                            
                            systemReportKeyList.push(key);
                            systemReportList.push(systemReport);
                        }                    
                    }
                    
                    // レポートIDの最大値を更新
                    // 表示中の記事のレポートIDリストの最後の値を取得
                    if (systemReportKeyList.length > 0){
                        setSystemReportIdLast(systemReportKeyList.slice(-1)[0]);
                    }
                }
                serverConnectFlg = false;
            });
        }
    }

    /**
     * 一番上なら top、一番下なら bottom、その他は scrolling の文字列を返す
     * 
     * @param {UIEvent} event
     * @returns {String} 'top' | 'bottom' | 'scrolling'
     */
    const getScrollVerticalPosition = e => {
        const {
        scrollHeight, scrollTop, clientHeight
        } = e.target;
    
        const isScrollTop = scrollTop === 0;
        // 完全に最下部に行かなくても検知するようにする
        const isScrollBottom = scrollHeight - clientHeight <= parseInt(scrollTop) + parseInt(scrollTop / 10);
        
        if (isScrollTop) {
        return 'top';
        } else if (isScrollBottom) {
        return 'bottom';
        } else {
        return 'scrolling';
        }
    }

    useEffect(() => {
        // 各記事の情報を取得
        if (firstLoadFlg){
            //firstLoadFlg = false;
            setFirstLoadFlg(false);
            getList();
        }

        /* 最下部へのスクロール到着を検知 */
        const parent = document.getElementById('report-list-area');
        parent.onscroll = (element) => {
            // スクロール時の処理
            if (getScrollVerticalPosition(element) === 'bottom'){
                // 最下部での処理
                // サーバーとの通信中でない場合は、各記事の情報を取得
                getList();
            }
            
        }
    });

    return (
        <div className='system-content'>
            <div id="report-list-area" className='report-list-area'>
                <ul>
                    {
                    systemReportList.map((reportInfo) => {
                            return (
                                <React.Fragment key={reportInfo.key}>
                                <li> <SystemListPc 
                                        reportId={reportInfo.key}
                                        title={reportInfo.title}
                                        thumbnailPath={reportInfo.thumbnailPath}
                                        tagList={reportInfo.tagList}
                                        prologue={reportInfo.prologue}
                                        updatedYmd={reportInfo.updatedYmd}
                                        views={reportInfo.views}
                                    /> </li>
                                </React.Fragment>
                            )
                    })}
                </ul>
            </div>
        </div>
    );
}

export default SystemPc;