/* 読込: モジュール */
import React, { useEffect } from 'react';
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/NotFound.css';
/* 読込: その他 */

function NotFoundSp (props) {

    useEffect(() => {
    });

    return (
        <div>
            <div id='error-content' className='error-content'>
                <p>指定されたページは存在しません</p>
            </div>
        </div>
    );
}

export default NotFoundSp;