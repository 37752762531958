/* 読込: モジュール */
import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
/* 読込: コンポーネント */
import HeaderPc from './elements/Header/HeaderPc';
import HeaderSp from './elements/Header/HeaderSp';
import Loading from './elements/Loading/Loading';
import TopPc from './elements/Top/TopPc';
import TopSp from './elements/Top/TopSp';
import NotFoundPc from './elements/NotFound/NotFoundPc';
import NotFoundSp from './elements/NotFound/NotFoundSp';
import SystemPc from './elements/System/SystemPc';
import SystemSp from './elements/System/SystemSp';
import SystemDetailPc from './elements/System/SystemDetailPc';
import SystemDetailSp from './elements/System/SystemDetailSp';
import DrawingPc from './elements/Paint/PaintPc';
import DrawingSp from './elements/Paint/PaintSp';
import DesignPc from './elements/Design/DesignPc';
import DesignSp from './elements/Design/DesignSp';
import MusicPc from './elements/Music/MusicPc';
import MusicSp from './elements/Music/MusicSp';
import ZinePc from './elements/Zine/ZinePc';
import ZineSp from './elements/Zine/ZineSp';
import ContactPc from './elements/Contact/ContactPc';
import ContactSp from './elements/Contact/ContactSp';
/* 読込: CSS */
import './css/App.css';
/* 読込: その他 */


function App() {

  const [device_type, setDeviceType] = useState("");
  const [isLoading, setIsLoading ] = useState(true);

  const loading_run_flg = useRef(false);

  /*
   * Expressからデバイスタイプを取得
   */
  const getDeviceType = () => {
    fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'device')
      .then(resp => resp.json())
      .then(res =>{
        setDeviceType(res);
      });
  }


  /* ローディングの実行 */
  const SetLoading = () => {
    /* ローディング画面終了処理 */
    if (isLoading && !loading_run_flg.current){
        loading_run_flg.current = true;

        var loacing_content = document.getElementById('loading_content');
        var loading_loader = document.getElementById('loading_loader');

        loacing_content.classList.remove('loading-content-action-feadout');
        loading_loader.classList.remove('loading-loader-action-fadeout');

        loacing_content.classList.add('loading-content-action-feadout');
        loading_loader.classList.add('loading-loader-action-fadeout');
        
        setTimeout(()=>{
          loading_run_flg.current = false;
          setIsLoading(false);
        },2000);
    }
}

  // 描画前の処理
  // useLayoutEffectに処理をいれるとローディング画面が表示されなくなる。
  useLayoutEffect(() => {
  });

  // 画面描画後の処理
  // 毎回実行 : useEffect (() => {})
  // 初回のみ実行 : useEffect (() => {},[])
  // 変数(例:x)が更新される度に実行 : useEffect (() => {}, [x])
  useEffect(() => {
    // 取得:デバイス情報
    getDeviceType();

    /* isLoadingがtrueに更新された場合 */
    if (isLoading && !loading_run_flg.current){
      // ローディング画面を表示
      SetLoading();;
    }
    // コンポーネントを離れる場合の処理
    return () => {
      
    };
  });

  return (
    <div id='all-container' className='all-container'>
      {/* Loading画面の表示 */}
      {/*<Loading />*/}
      {isLoading && <Loading />}
      <div className='content-container'>
        <Router>
          {device_type === 'PC' ?
            <HeaderPc />
            : <HeaderSp />
          }
          <Routes>
            {/* TOPページ */}
            {device_type === 'PC' ?
              <Route path="/" element={<TopPc/>} />
              : <Route path="/" element={<TopSp/>} />
            }
            {/* 個別ページ:システム用 */}
            {device_type === 'PC' ?
              <Route path="/system" element={<SystemPc/>} />
              : <Route path="/system" element={<SystemSp/>} />
            }
            {device_type === 'PC' ?
              <Route path="/system/detail/:id" element={<SystemDetailPc/>} />
              : <Route path="/system/detail/:id" element={<SystemDetailSp/>} />
            }
            {/* 個別ページ:絵用 */}
            {device_type === 'PC' ?
              <Route path="/paint" element={<DrawingPc/>} />
              : <Route path="/paint" element={<DrawingSp/>} />
            }
            {/* 個別ページ:デザイン用 */}
            {device_type === 'PC' ?
              <Route path="/design" element={<DesignPc/>} />
              : <Route path="/design" element={<DesignSp/>} />
            }
            {/* 個別ページ:音楽用 */}
            {device_type === 'PC' ?
              <Route path="/music" element={<MusicPc/>} />
              : <Route path="/music" element={<MusicSp/>} />
            }
            {/* 個別ページ:ZINE用 */}
            {device_type === 'PC' ?
              <Route path="/zine" element={<ZinePc/>} />
              : <Route path="/zine" element={<ZineSp/>} />
            }
            {/* 個別ページ:連絡先用 */}
            {device_type === 'PC' ?
              <Route path="/contact" element={<ContactPc/>} />
              : <Route path="/contact" element={<ContactSp/>} />
            }
            {/* 個別ページ:NOT FOUND用 */}
            {device_type === 'PC' ?
              <Route path="*" element={<NotFoundPc/>} />
              : <Route path="*" element={<NotFoundSp/>} />
            }
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
