/* 読込: モジュール */
import React, {useEffect, useState} from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Music.css';
/* 読込: その他 */
import ImgCinderellaBoys from '../../images/music/band-image/img_cinderellaboys_creating.jpg';
import ImgClassRoom from '../../images/music/band-image/img_classroom_creating.jpg';
import VideoCinderellaBoys from '../../images/music/background-image/background_cinderellaboys.mp4';
import VideoClassRoom from '../../images/music/background-image/background_classroom.mp4';
import ImgExLink from '../../images/icon/icon_exlink.svg';
import ImgExLinkSelected from '../../images/icon/icon_exlink_selected.svg';


function MusicPc (props) {
    var backgroundVideo = '';
    const [exLinkCinderellaboys, setExLinkCinderellaboys] = useState(ImgExLink);
    const [exLinkClassroom, setExLinkClassroom] = useState(ImgExLink); 


    useEffect(() => {
        // 背景動画の呼び出し
        var backgroundVideoAreaCinderellaboys = document.getElementById('background-video-cinderellaboys');
        var backgroundVideoAreaClassroom = document.getElementById('background-video-classroom');

        // 画像の呼び出し
        var imgCinderellaboys = document.getElementById('img-cinderellaboys');
        var imgClassroom = document.getElementById('img-classroom');

        // タイトルの呼び出し
        var titleCinderellaboys = document.getElementById('title-cinderellaboys');
        var titleClassroom = document.getElementById('title-classroom');

        /* 背景画像の大きさを画面サイズに指定 */
        var imgContentArea = document.getElementById('music-content');
        if (imgContentArea) {
            imgContentArea.style.width = window.innerWidth;
            imgContentArea.style.height = window.innerHeight;
        }
        
        var widthBeforeCinderellaboys = "50%";
        var widthAfterCinderellaboys = "100%"
        var widthBeforeClassroom = "50%";
        var widthAfterClassroom = "100%"
        var duration = 300;

        /* マウスオーバーイベントの追加 : シンデレラボーイズ */
        var contentAreaCinderellaboys = document.getElementById('content-cinderellaboys');
        if (contentAreaCinderellaboys) {
            //マウスが要素上に入った時
            contentAreaCinderellaboys.addEventListener('mouseover', () => {
                if (backgroundVideoAreaCinderellaboys){
                    if (backgroundVideo !== 'シンデレラボーイズ'){
                        if (backgroundVideo === 'CLASSROOM'){
                            widthBeforeCinderellaboys = "0%";
                            widthAfterCinderellaboys = "100%";
                        }

                        backgroundVideo = 'シンデレラボーイズ';

                        backgroundVideoAreaCinderellaboys.animate(
                            {width: [widthBeforeCinderellaboys, widthAfterCinderellaboys]},
                            {direction: "normal",
                            easing: "ease-in",
                            duration: duration,
                            fill: "forwards"
                            }
                        );

                        backgroundVideoAreaClassroom.animate(
                            {width: [widthAfterClassroom, "0%"]},
                            {direction: "normal",
                            easing: "ease-in",
                            duration: duration,
                            fill: "forwards"},
                        );

                        imgCinderellaboys.style = 'border: solid 4px; border-color:yellow';
                        imgClassroom.style = '';

                        titleCinderellaboys.style = 'color:yellow';
                        titleClassroom.style = 'color:white';
                        setExLinkCinderellaboys(ImgExLinkSelected);
                        setExLinkClassroom(ImgExLink);
                    }
                }
            }, false);
        }

        /* マウスオーバーイベントの追加 : CLASSROOM */
        var contentAreaClassroom = document.getElementById('content-classroom');
        if (contentAreaClassroom) {
            //マウスが要素上に入った時
            contentAreaClassroom.addEventListener('mouseover', () => {
                if (backgroundVideoAreaClassroom){
                    if (backgroundVideo !== 'CLASSROOM'){
                        /// 再生中でない場合は、動画を再生
                        if (backgroundVideo === 'シンデレラボーイズ'){
                            widthBeforeClassroom = "0%";
                            widthAfterClassroom = "100%";
                        }

                        backgroundVideo = 'CLASSROOM';

                        backgroundVideoAreaClassroom.animate(
                            {width: [widthBeforeClassroom, widthAfterClassroom]},
                            {direction: "normal",
                            easing: "ease-in",
                            duration: duration,
                            fill: "forwards"
                            }
                        );

                        backgroundVideoAreaCinderellaboys.animate(
                            {width: [widthAfterCinderellaboys, "0%"]},
                            {direction: "normal",
                            easing: "ease-in",
                            duration: duration,
                            fill: "forwards"},
                        );
                        
                        imgCinderellaboys.style = '';
                        imgClassroom.style = 'border: solid 4px; border-color:yellow';

                        titleCinderellaboys.style = 'color:white';
                        titleClassroom.style = 'color:yellow';

                        setExLinkCinderellaboys(ImgExLink);
                        setExLinkClassroom(ImgExLinkSelected);
                    }
                }
            }, false);
        }
    });

    return (
        <div id='music-content' className='music-content'>
            <video id='background-video-cinderellaboys' className='mp4-background' src={VideoCinderellaBoys} poster={ImgCinderellaBoys} muted autoPlay loop></video>
            <video id='background-video-classroom' className='mp4-background' src={VideoClassRoom} poster={ImgClassRoom} muted autoPlay loop></video>
            <div className='img-content'>
                <div id='content-cinderellaboys' className='band-content band-content-cinderellaboys'>
                    <img id='img-cinderellaboys' src={ImgCinderellaBoys} alt='シンデレラガールズ アイコン'></img>
                    <div>
                        <p id='title-cinderellaboys'>シンデレラボーイズ</p>
                        <img src={exLinkCinderellaboys} />
                    </div>
                </div>
                <div id='content-classroom' className='band-content band-content-classroom'>
                    <img id='img-classroom' src={ImgClassRoom} alt='CLASSROOM アイコン'></img>
                    <div>
                        <p id='title-classroom'>CLASSROOM</p>
                        <img src={exLinkClassroom} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MusicPc;