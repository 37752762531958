/* 読込: モジュール */
import React from 'react';
import { useLocation } from "react-router-dom";
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Header.css';
/* 読込: その他 */
import ImgLogo from '../../images/Logo_2.png';
/*import ImgMenu from '../../images/menu_sp.svg';*/

function HeaderSp (props) {
    // ユーザがアクセスしたロケーションを取得
    const path_name = useLocation().pathname;
    var logo_link_flg = true;   // ロゴにTOPページに遷移するリンクを設定するかどうか
    var navi_show_flg = true;   // ヘッダー内のメニューを表示するかどうか

    /* パスごとにメニューの表示・非表示を変更 */
    switch (path_name) {
        case '/':
            logo_link_flg = false;
            break;
        case '/aaa':
            break;
        default :
            /* 表示する要素を指定 */
            navi_show_flg = false;
    }

    return (
        <header>
            {navi_show_flg ?
            <div id="header-container" className='header-container-common header-container-sp'>
                {logo_link_flg ?
                <a href="http://localhost:3000/">
                    <img className='header-logo-sp' src={ImgLogo} alt='Logo' />
                </a>
                : <img className='header-logo-sp' src={ImgLogo} alt='Logo' />
                }
                {/* メニュー用 */}
                {/*<img className='header-navi-sp' src={ImgMenu} alt='Menu' />*/}
            </div>
            
            :<div className='header-container-common header-container-sp'>
                <div>
                    {logo_link_flg ?
                    <a href="http://localhost:3000/">
                        <img className='header-logo-sp' src={ImgLogo} alt='Logo'/>
                    </a>
                    : <img className='header-logo-sp' src={ImgLogo} alt='Logo'/>
                    }
                </div>
            </div>
            }
        </header>
    );
}

export default HeaderSp;