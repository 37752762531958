/* 読込: モジュール */
import React, {useState, useEffect} from 'react'
import Modal from 'react-modal'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Paint.css';
/* 読込: その他 */

/* 参照サイト */
// react-modal : https://gladevise.com/react-modal-usage

function DrawingPc (props) {
    const dayList = ["日", "月", "火", "水", "木", "金", "土"]; // 曜日確認用
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0)'
        },
        content: {
            width: '60%',
            height: '70%',
            top: '15%',
            left: '20%',
            right: 'auto',
            bottom: 'auto',
            background: 'rgba(252, 250, 235, 0.94)',
            border: '0px',
            borderRadius: '20px',
            transition: 'opacity 200ms ease-in-out',
        }
    };                                                        // モーダルのレイアウト用
    
    const [windowWidthSize, setWindowWidthSize] = useState(0);              // 画面の幅を取得
    const [paintList, setPaintList] = useState([]);             // 一覧表示する画像
    const [paintIdLast, setPaintIdLast] = useState(9999);       // 一度に一覧表示する画像の最大ID (初期アクセス時は0)
    const [firstLoadFlg, setFirstLoadFlg] = useState(true);     // 1回目の読み込みかどうか (true:1回目の読み込み false:2回目以降の読み込み)
    const [modalOpenFlg, setModalOpenFlg] = useState(false);    // 画面内ウィンドウ(モーダルウィンドウ)を開くかどうか (true:開く false:開かない)
    const [modalImgPath, setModalImgPath] = useState('');       // モーダル用 : 画像のパス
    const [modalTagList, setModalTagList] = useState([]);       // モーダル用 : タグの一覧
    const [modalCreatedYmd, setModalCreatedYmd] = useState(''); // モーダル用 : 作成日付

    var serverConnectFlg = false;   // サーバーとの通信中かどうかを管理 (true:通信中 false:通信中でない)
    var paintKeyList = [];          // 表示している記事のキー配列

    const getList = async() => {
        if (!serverConnectFlg) {
            serverConnectFlg = true;

            const params = { // 渡したいパラメータをJSON形式で書く
                paintIdLast: paintIdLast,
                paintCount: process.env.REACT_APP_PAINT_COUNT,
                sortType: 1,
                sortTypeDateTime: 1
            };

            const query_params = new URLSearchParams(params);
            await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'paint/list?' + query_params)
            .then(resp => resp.json())
            .then(res =>{
                // Expressの処理でエラーがあった場合
                if (Array.isArray(res.errors) && res.errors.length > 0){
                    for (var i = 0; i < res.errors.length; i++){
                        var error = res.errors[i];
                        console.error(error.path + "(val:" + error.value + "):" + error.msg);
                    }
                }else{
                    // データをセット
                    const resList = JSON.parse(res);
                    for (let key in resList) {
                        if (paintKeyList.indexOf(resList[key]['datPaint']['paintId']) === -1){
                            // 日付の形式を変更
                            var createDateStr = resList[key]['datPaint']['createdAt'].substr(0, 10);
                            createDateStr = createDateStr.replace(/-/g, '/');
                            const createDate = new Date(createDateStr);

                            // 配列にキーがない場合のみ追加
                            var paintInfo= {
                                key: resList[key]['datPaint']['paintId'],
                                paintFilePath:resList[key]['paintFile'],
                                tagList:resList[key]['tagList'],
                                createdYmd: createDateStr + ' (' + dayList[createDate.getDay()] + ')',
                            };
                            
                            paintKeyList.push(resList[key]['datPaint']['paintId']);
                            paintList.push(paintInfo);
                        }
                    }

                    // ペイントIDの最大値を更新
                    // 表示中の記事のペイントIDリストの最後の値を取得
                    if (paintKeyList.length > 0){
                        setPaintIdLast(paintKeyList.slice(-1)[0]);
                    }
                }
                serverConnectFlg = false;
            });   
        }
    }


        /**
     * 一番上なら top、一番下なら bottom、その他は scrolling の文字列を返す
     * 
     * @param {UIEvent} event
     * @returns {String} 'top' | 'bottom' | 'scrolling'
     */
    const getScrollVerticalPosition = e => {
        const {
        scrollHeight, scrollTop, clientHeight
        } = e.target;
    
        const isScrollTop = scrollTop === 0;
        // 完全に最下部に行かなくても検知するようにする
        const isScrollBottom = scrollHeight - clientHeight <= parseInt(scrollTop) + parseInt(scrollTop / 10);
        
        if (isScrollTop) {
        return 'top';
        } else if (isScrollBottom) {
        return 'bottom';
        } else {
        return 'scrolling';
        }
    }

    /**
     * 画像クリック時の処理
     * 引数 : paintInfo
     *          - createdYmd     : 作成日付 (例:2024/1/24 (水))
     *          - key           : 識別キー (例:26)
     *          - paintFilePath : ファイルのURL (例:http://localhost:3001/static/paint/26.jpg)
     *          - taglist       : タグの一覧 (配列)
     */
    function openModal(paintInfo) {
        setModalOpenFlg(true);
        setModalImgPath(paintInfo.paintFilePath);
        setModalTagList(paintInfo.tagList);
        setModalCreatedYmd("作成日: " + paintInfo.createdYmd);
    }

    useEffect(() => {
        setWindowWidthSize(window.innerWidth);
        
        window.addEventListener("resize", () => {
            setWindowWidthSize(window.innerWidth);
        });

        if(firstLoadFlg){
            setFirstLoadFlg(false);
            getList();
        }

        /* 最下部へのスクロール到着を検知 */
        const parent = document.getElementById('paint-area-parent');
        parent.onscroll = (element) => {
            // スクロール時の処理
            if (getScrollVerticalPosition(element) === 'bottom'){
                // 最下部での処理
                // サーバーとの通信中でない場合は、各記事の情報を取得
                getList();
            }
        }
    })

    return (
        <div className="paint-content">
            <div id="paint-area-parent">
                <div>
                    {/* 画像クリック時に詳細用モーダルを表示
                        isOpen : 表示するかどうか
                        onRequestClose : モーダル外部をクリックしたときの処理
                        style: モーダルのスタイル
                    */}
                    <Modal
                        isOpen={modalOpenFlg}
                        onRequestClose={() => setModalOpenFlg(false)}
                        style={customStyles}
                        closeTimeoutMS={300}
                        ariaHideApp={false}
                        >
                        <div className={windowWidthSize >= 850 ? "paint-modal-parent-850-over" : "paint-modal-parent-850-under"}>
                            <div className={windowWidthSize >= 850 ? 'paint-modal-img-parent-850-over' : 'paint-modal-img-parent-850-under'}>
                                <img src={modalImgPath}/>
                            </div>
                            <div className={windowWidthSize >= 850 ? 'paint-modal-tag-ymd-parent-850-over' : 'paint-modal-tag-ymd-parent-850-under'}>
                                <div className='paint-mansory-tag'>
                                    <ul>
                                        {
                                        modalTagList.map((tag, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                <li> {tag} </li>
                                                </React.Fragment>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <hr />
                                <div className={windowWidthSize >= 850 ? 'paint-modal-ymd-parent-850-over' : 'paint-modal-ymd-parent-850-under'}>
                                    <span>{modalCreatedYmd}</span>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* 画面幅に合わせて要素数(横を調整) */}
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 2, 800: 3, 1100: 4, 1300: 5, 1500: 6}}
                    >
                        <Masonry>
                            {paintList.map((paintInfo) => (
                                <a href='#' onClick={() => openModal(paintInfo)} key={paintInfo.key} className='paint-masonry-link'>
                                    <div key={paintInfo.key} className="paint-masonry-parent">
                                        <img className="paint-masonry-img"
                                            src={paintInfo.paintFilePath}
                                        />
                                        <div className="paint-mansory-tag">
                                            {/* タグエリア */}
                                            <ul>
                                                {
                                                paintInfo.tagList.map((tag, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                        <li> {tag} </li>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className='paint-mansory-datetime'>
                                            {/* 作成年月日 */}
                                            <span>{paintInfo.createdYmd}</span>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </div>
    );
}

export default DrawingPc;