/* 読込: モジュール */
import React, {useEffect, useState} from 'react'
import { useLocation } from "react-router-dom";
/* 読込: コンポーネント */
/* 読込: CSS */
import '../../css/Header.css';
/* 読込: その他 */
import ImgLogoDesktop from '../../images/logo_4.png';
import ImgLogoOther from '../../images/logo_3.png';
/*import ImgIconSystem from '../../images/icon/icon_system.svg';
import ImgIconDrawing from '../../images/icon/icon_paint.svg';
import ImgIconDesign from '../../images/icon/icon_design.svg';
import ImgIconMusic from '../../images/icon/icon_music.svg';
import ImgIconZine from '../../images/icon/icon_zine.svg';
import ImgIconContact from '../../images/icon/icon_contact.svg';
import ImgNaviSearch from '../../images/header/navi/search.svg';
*/
import ImgNaviSortNotDate from '../../images/header/navi/sortDateNotSet.svg';
import ImgNaviSortNotView from '../../images/header/navi/sortViewNotSet.svg';
import ImgNaviSortDate from '../../images/header/navi/sortDateSet.svg';
import ImgNaviSortView from '../../images/header/navi/sortViewSet.svg';


function HeaderPc (props) {
    // ユーザがアクセスしたロケーションを取得
    const path_name = useLocation().pathname;
    var logo_link_flg = true;       // ロゴにTOPページに遷移するリンクを設定するかどうか (true:リンクを表示 false:リンクを非表示)
    var icon_show_flg = true;       // ヘッダー内のメニューを表示するかどうか (true:メニューを表示 false:メニューを非表示)
    var navi_show_flg = false;      // ヘッダー内に検索等のナビゲーションを表示するかどうか (true:ナビを表示 false:ナビを非表示)
    var header_fixed_flg = true;    // ヘッダーを上部に固定するかどうか (true:固定する false:固定しない)
    var header_class = 'header-container-common header-container-pc-clear';

    // システム画面の検索ワード
    const [searchVal, setSearchVal] = useState("");
    // 並び順タイプ (1:日付 2:閲覧数)
    const [sortType, setSortType] = useState(1);

    /**
     * 並び順を変更
     */
    function sortTypeChange(clickSortType) {
        if (clickSortType === 2 & sortType === 1) {
            setSortType(2);
        }else if(clickSortType === 1 & sortType === 2) {
            setSortType(1);
        }
    }

    switch (true) {
        case path_name === '/':
            logo_link_flg = false;
            icon_show_flg = false; 
            header_fixed_flg = false;
            break;
        case path_name === '/system':
            navi_show_flg = true;
            header_class = 'header-container-common header-container-pc';
            break;
        case path_name.indexOf('/system/detail/') === 0 :
        case path_name === '/paint':
        case path_name === '/design':
        case path_name === '/music':
        case path_name === '/zine':
        case path_name === '/contact':
            break;
        default :
            /* NOT FOUND用 */
            icon_show_flg = false;
    }
    
    useEffect(() => {
        if(!header_fixed_flg){
            /* ヘッダーを上部に固定しない場合は、スタイルを変更 */
            var headerParent = document.getElementById('header-parent');
            if (headerParent){
                headerParent.style.position = 'static';
            }
        }
    });

    return (
        <header id='header'>
                {/* 各ページへのリンクを表示するかどうか */}
                {icon_show_flg ?
                <div id='header-content' className={header_class}>
                    <div id='header-parent' className='header-icon-parent-pc'>
                        <nav className='header-navi-pc'>
                            <ul className='header-navi-pc-ul-left'>
                                {(path_name === '/system' || path_name.indexOf('/system/detail/') === 0) ?
                                <li className='navi-pc-item-common navi-pc-item-system-selected'><a href="/system" style={{color:"#fdad15"}}>system</a></li>
                                : <li className='navi-pc-item-common navi-pc-item-system-not-selected'><a href="/system">system</a></li>
                                }

                                {path_name === '/paint' ?
                                <li className='navi-pc-item-common navi-pc-item-paint-selected'><a href="/paint" style={{color:"#fdad15"}}>paint</a></li>
                                : <li className='navi-pc-item-common navi-pc-item-paint-not-selected'><a href="/paint">paint</a></li>
                                }

                                {path_name === '/design' ?
                                <li className='navi-pc-item-common navi-pc-item-design-selected'><a href="/design" style={{color:"#fdad15"}}>design</a></li>
                                : <li className='navi-pc-item-common navi-pc-item-design-not-selected'><a href="/design">design</a></li>
                                }
                            </ul>
                        </nav>
                        {/* 中央のロゴを表示するかどうか */}
                        {logo_link_flg ?
                        <a href="/">
                            <img className='header-logo-pc' src={ImgLogoOther} alt="Logo" />
                        </a>
                        : <img className='header-logo-pc' src={ImgLogoDesktop} alt="Logo" />
                        }
                        <nav className='header-navi-pc'>
                            <ul className='header-navi-pc-ul-right'>
                                {path_name === '/music' ?
                                <li className='navi-pc-item-common navi-pc-item-music-selected'><a href="/music" style={{color:"#fdad15"}}>music</a></li>
                                : <li className='navi-pc-item-common navi-pc-item-music-not-selected'><a href="/music">music</a></li>
                                }

                                {path_name === '/zine' ?
                                <li className='navi-pc-item-common navi-pc-item-zine-selected'><a href="/zine" style={{color:"#fdad15"}}>zine</a></li>
                                : <li className='navi-pc-item-common navi-pc-item-zine-not-selected'><a href="/zine">zine</a></li>
                                }

                                {path_name === '/contact' ?
                                <li className='navi-pc-item-common navi-pc-item-contact-selected'><a href="/contact" style={{color:"#fdad15"}}>contact</a></li>
                                : <li className='navi-pc-item-common navi-pc-item-contact-not-selected'><a href="/contact">contact</a></li>
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
                :   <div id='header-parent' className='header-container-common header-container-pc-no-icon'>
                        {/* 中央のロゴを表示するかどうか */}
                        {logo_link_flg ?
                        <a href="/">
                            <img className='header-logo-pc' src={ImgLogoDesktop} alt="Logo" />
                        </a>
                        : <img className='header-logo-pc' src={ImgLogoDesktop} alt="Logo" />
                        }
                    </div>
                }
            
        </header>
    );
}

export default HeaderPc;