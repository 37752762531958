/* 読込: モジュール */
import React, {useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";
/* 読込: コンポーネント */
import SystemDetailIndexPc from './SystemDetailIndexPc';
import SystemDetailContentPc from './SystemDetailContentPc';
/* 読込: CSS */
import '../../css/System.css';
/* 読込: その他 */
import ImgSystemReportNotFoundOne from '../../images/system/error/system_nof_found.webp';
import ImgSystemReportNotFoundTwo from '../../images/system/error/system_nof_found.png';

const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

function SystemDetailPc (props) {

    const dayList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]; // 曜日確認用
    const errorMessage = "指定された記事は表示できません。";

    const [reportId, setReportId] = useState(0);
    const [title, setTitle] = useState("");
    const [thumbnailPath, setThumbnailPath] = useState("");
    const [tagList, setTagList] = useState([]);
    const [updatedYmd, setUpdatedYmd] = useState("");
    const [view, setView] = useState(0);
    const [markdownContent, setMarkdownContent] = useState("");
    const [reportShowFlg, setReportShowFlg] = useState(true);       // 記事の表示が可能かどうか (true:表示可能 false:表示不可能)
    const [imgSystemReportNotFound, setImgSystemReportNotFound] = useState("");

    let urlPathphrase = '/system/detail/';  // URLの正しい形式
    const url = useLocation().pathname;     // URLを取得 (例:/system/detail/1)
    var serverConnectFlg = false;           // サーバーとの通信中かどうか (true:通信中 false:通信中でない)

    /*
    * Expressから記事情報を取得
     *  - reportId(int) : 取得する記事の記事ID
    */

    const getDetail = async() => {
        if (!serverConnectFlg) {
            serverConnectFlg = true;

            const params = { // 渡したいパラメータをJSON形式で書く
                reportId: reportId,
                viewType: 2
            };

            const query_params = new URLSearchParams(params); 
            await fetch(process.env.REACT_APP_BACKEND_BASE_URL + 'system/detail?' + query_params)
            .then(resp => resp.json())
            .then(res =>{
                // Expressの処理でエラーがあった場合
                if (Array.isArray(res.errors) && res.errors.length > 0 ){
                    // バリデートエラーの場合
                    for (var i = 0; i < res.errors.length; i++){
                        var error = res.errors[i];
                        console.error(error.path + "(val:" + error.value + "):" + error.msg);
                    }

                    (async () => {
                        await sleep(800);
                        // Webpをセット
                        setImgSystemReportNotFound(ImgSystemReportNotFoundOne);
                        await sleep(500);
                        // PNGをセット
                        setImgSystemReportNotFound(ImgSystemReportNotFoundTwo);
                    })();

                    //reportShowFlg = false;
                    setReportShowFlg(false);
                } else if (res.errors !== undefined && res.errors.length > 0 ) {
                        // 以下のいずれかのエラーがあった場合
                        // 記事IDがない場合
                        // 記事が非公開の場合
                        // 記事ディレクトリまたはディレクトリ内に必要なファイルがない場合
                        console.error(res.errors);
                        //reportShowFlg = false;

                        (async () => {
                            await sleep(800);
                            // Webpをセット
                            setImgSystemReportNotFound(ImgSystemReportNotFoundOne);
                            await sleep(500);
                            // PNGをセット
                            setImgSystemReportNotFound(ImgSystemReportNotFoundTwo);
                        })();

                        setReportShowFlg(false);
                }else{
                    // データをセット
                    const resDetail = JSON.parse(res);

                    // 日付の形式を変更
                    var updateDateStr = resDetail.systemDatSystem.updatedAt.substr(0, 10);
                    updateDateStr = updateDateStr.replace(/-/g, '/');
                    const updateDate = new Date(updateDateStr);
                    
                    // コンポーネントに値をセット
                    setTitle(resDetail['systemDatSystem']['systemReportTitle']);
                    setThumbnailPath(resDetail['thumbnail']);
                    for (var i = 0; i < resDetail['tagList'].length; i++ ){
                        tagList.push(resDetail['tagList'][i]);
                    }
                    setUpdatedYmd(updateDateStr + ' (' + dayList[updateDate.getDay()] + ')');
                    setView(resDetail['view']);
                    setMarkdownContent(resDetail['markdownContent']);
                }
                serverConnectFlg = false;
            });
        }
    }

    useEffect(() => {
        // URLから記事IDを取得
        if (url.indexOf(urlPathphrase) === -1){
            // URLに問題がある場合
            // エラー画面を表示 (URLがただしくありません)
        }else{
            // URLに問題がない場合
            // 記事IDをセット
            setReportId(Number(url.slice(urlPathphrase.length)));
            
            // 記事情報をサーバーから取得
            if (reportId !== 0){
                getDetail();
            }
        }
    },[reportId]);

    return (
        <div className='system-content'>
            <div id='report-parent' className='report-parent'>
                <div className='report-content'>
                    {/* 内容 */}
                    {reportShowFlg ?
                    /* 記事があった場合 */
                    <>
                        <SystemDetailContentPc 
                            reportId={reportId}
                            title={title}
                            thumbnailPath={thumbnailPath}
                            tagList={tagList}
                            updatedYmd={updatedYmd}
                            view={view}
                            markdownContent={markdownContent}
                        />
                        {/* もくじ */}
                        <SystemDetailIndexPc 
                            markdownContent={markdownContent}
                        />
                    </>
                    :
                    /* 記事がなかった場合 */
                    <div className='report-not-found'>
                        <span>{errorMessage}</span>
                        <img id='img_not_found' src={imgSystemReportNotFound} alt='NOT FOUND REPORT' />
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SystemDetailPc;