/* 店主の会話をゆっくり表示するための処理を管理 */
/* 目的 : 会話用のspanタグに、会話のpタグをゆっくり追加
 * 引数1 (parentSpanId) : 会話用のpタグの親であるspanタグのID (string)
 * 引数2 (talkerName) : 話してる人の名前 (string)
 * 引数3 (talkContentList) : 会話の内容 (配列)
 *         配列のイメージ
 *              - 要素1 : 1行目の文字列
 *              - 要素2 : 2行目の文字列
 * 引数4 (imgId) : 背景画像のID
 * 引数5 (imgPathBefore): 会話中の会話の背景画像のパス
 * 引数6 (imgPathAfter) : 会話後の会話の背景画像のパス
 * 戻り値 : なし
 */
var runCountMax = 0;
let currentPromise = null; // 現在のPromiseを追跡する変数

const ActionTalk = (parentSpanId, talkerName, talkContentList, imgIdAction, imgPathAction, imgIdSelected='',imgPathSelected='') => {
  // 複数実行の管理用
  runCountMax++;
  var runCount = runCountMax;

  const sleep = msec => {
    let cancelFunc;
    const promise = new Promise((resolve, reject) => {
        cancelFunc = () => reject(new Error('Canceled'));
        setTimeout(() => resolve(), msec);
    });

    promise.cancel = cancelFunc;
    return promise;
  };

  const executeTalk = async () => {
    var talkSpan = 50; // 会話のスピード(ms)
    var talkLineSpan = 700; // 1行ごとの間隔(ms)
    var talkLine = 1;

    //会話内容を初期化
    const parentSpan = document.getElementById(parentSpanId);
    while (parentSpan.firstChild) {
        parentSpan.removeChild(parentSpan.firstChild);
    }

    var imageAction = document.getElementById(imgIdAction);

    // 会話中の画像をセット
    imageAction.style.visibility = 'visible';
    imageAction.src = imgPathAction;

    // 要素がマウスオーバーされている場合は、選択状態の画像をセット
    if (imgIdSelected !== '' && imgPathSelected !== ''){
      var imageSelected = document.getElementById(imgIdSelected);
      imageSelected.style.visibility = 'visible';
      imageSelected.src = imgPathSelected;
    }

    // 話している人の名前をセット
    var pElement = document.createElement('p');
    pElement.id = 'talker';
    parentSpan.appendChild(pElement);
    var commentElement = document.getElementById('talker');
    commentElement.innerHTML = talkerName + ' :';

    await sleep(talkLineSpan);


    try {
      for (const talkContent of talkContentList) {
        // 各行ごとの処理

        // 多重実行チェック
        if (runCountMax !== runCount) {
          throw new Error('Canceled');
        }

        pElement = document.createElement('p');
        pElement.id = 'talk-content-' + String(talkLine);
        parentSpan.appendChild(pElement);

        commentElement = document.getElementById('talk-content-' + String(talkLine));
      
        for (var i = 0; i < talkContent.length; i++) {
          // 各文字ごとの処理
          commentElement.innerHTML += talkContent[i];
          
          // 多重実行チェック
          if (runCountMax !== runCount) {
            throw new Error('Canceled');
          }
          
          await sleep(talkSpan);
        }

        // 多重実行チェック
        if (runCountMax !== runCount) {
          throw new Error('Canceled');
        }

        talkLine++;
        await sleep(talkLineSpan);
      }
    } catch (error) {
        // 多重実行をしていた場合は、古いものを停止
        return false;
    }

    // 会話終了後の画像をセット
    imageAction.style = 'hidden';
    //image.src = imgPathAfter;
    runCountMax = 0;

    /* 他要素が選択されるまでは、選択状態を保持したいので、実行不要
    // 要素がマウスオーバーされている場合は、選択状態の画像をセット
    if (imgIdSelected !== '' && imgPathSelected !== ''){
      //var imageSelected = document.getElementById(imgIdSelected);
      //imageSelected.style.visibility = 'hidden';
    }

    // 会話完了を示すaタグを作製
    /*
    var talkEndCheckElement = document.createElement('a');
    talkEndCheckElement.id = 'talk-end';
    parentSpan.appendChild(talkEndCheckElement);
    */
    

    return true;
  };

  currentPromise = executeTalk();
  currentPromise.isCanceled = false;

};

// 各コンポーネントから参照できるようにする
module.exports = ActionTalk;