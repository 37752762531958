/* 読込: モジュール */
import React, { useEffect } from 'react'
/* 読込: コンポーネント */
/* 読込: CSS */
/* 読込: その他 */
import ImgTop from '../../images/top_sp.gif'

function TopSp (props) {

    useEffect(() => {
    });

    return (
        <div>
            <div id='top-content' className='top-content'>
                <img src={ImgTop} alt='Top' />
            </div>
        </div>
    );
}

export default TopSp;